import React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";

const hero = [
  {
    title: "Privacy",
  },
];
const breadcrumb = [{ title: "Home", link: "/" }, { title: "Privacy" }];

const Privacy = ({ location }) => {
  return (
    <Layout location={location} hero={hero} breadcrumb={breadcrumb}>
      <Seo
        title={`Privacy`}
        description={`Ariadne è la società del gruppo Ariadne Digital che, da oltre 25 anni, è specializzata nello sviluppo di piattaforme digitali evolute`}
      />

      <div className="section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p>
                Ariadne S.r.l., proprietaria del presente sito web, si impegna a proteggere la
                privacy dei propri utenti e a tutelare i dati personali raccolti nel rispetto del
                "Codice in materia di protezione dei dati personali" (D.Lgs. 196/03).
              </p>
              <p>
                L'informativa è resa ai sensi dell'art. 13 del D.Lgs. 30 giugno 2003, n. 196 e del
                Regolamento Europeo in materia di Protezione dei Dati Personali (2016/679) per gli
                utenti che consultano e interagiscono questo sito e usufruiscono dei servizi
                forniti.
              </p>
              <p>
                L'informativa è resa solo per il sito "www.ariadne.it" (d'ora in avanti "Sito
                Ariadne"), e non anche per altri siti web eventualmente raggiunti dall'utente
                tramite link.
              </p>
              <h3>Finalità del trattamento</h3>
              <p>
                <strong>Titolare del trattamento</strong>
              </p>
              <p>
                Ariadne S.r.l. con sede legale in Via Visconti di Modrone, 11 - 20122 - MILANO
                (Italia) (la “Società”, “Ariadne S.r.l.” o il “Titolare”) garantisce il rispetto
                della disciplina in materia di protezione dei dati personali fornendo le seguenti
                informazioni circa il trattamento dei dati comunicati o comunque raccolti nel corso
                della navigazione sul presente sito.{" "}
              </p>
              <p>
                <strong>Dati trattati, finalità e basi giuridiche del trattamento</strong>
              </p>
              <p>
                <em>Dati generati dall’accesso al sito</em> <br />I sistemi informatici e le
                procedure software preposte al funzionamento di questo sito acquisiscono, nel corso
                del loro normale esercizio, alcuni dati personali la cui trasmissione è implicita
                nell’uso dei protocolli di comunicazione di Internet. Questi dati (quali ad esempio
                nomi di dominio, indirizzi IP, sistema operativo utilizzato, tipo di device di
                browser utilizzati per la connessione) non sono accompagnati da alcuna informazione
                personale aggiuntiva e vengono utilizzati per: i) ricavare informazioni statistiche
                anonime sull’uso del sito; ii) gestire esigenze di controllo delle modalità di
                utilizzo dello stesso, iii) accertare responsabilità in caso di ipotetici reati
                informatici. La base giuridica che legittima il trattamento di tali dati è la
                necessità di rendere utilizzabili le funzionalità del sito a seguito dell’accesso
                dell’utente.
              </p>
              <em>Dati forniti volontariamente dall’utente </em> <br />
              <p>
                I dati personali forniti dall’utente tramite form sono raccolti e trattati per le
                seguenti finalità:
              </p>
              <ul>
                <li>per lo svolgimento delle attività di relazione con il cliente</li>
                <li>
                  per finalità amministrative e per l’adempimento di obblighi di legge quali ad
                  esempio quelli di natura contabile, fiscale, o per dar corso a richieste
                  dell’autorità giudiziaria;
                </li>
                <li>
                  per l’invio periodico, tramite e-mail, di newsletter di aggiornamento sulle nostre
                  attività, materiale informativo, inviti ad eventi;
                </li>
                <li>
                  nel caso di invio di curriculum vitae, esclusivamente per finalità di selezione.
                </li>
              </ul>
              <p>
                La base giuridica che legittima il trattamento è l’esecuzione di un contratto di cui
                l’interessato è parte o l’esecuzione di misure precontrattuali adottate su richiesta
                dello stesso. Nei casi espressamente indicati la base giuridica è il consenso
                liberamente fornito dall’interessato.{" "}
              </p>
              <h3>Modalità del trattamento</h3>
              <p>
                <strong>Dati forniti volontariamente dall'utente</strong>
              </p>
              <p>
                Il trattamento dei dati avrà luogo con modalità sia automatizzate che manuali, e nel
                rispetto delle regole di riservatezza e di sicurezza previste dalla legge. La
                raccolta di queste informazioni avviene unicamente attraverso i form presenti nel
                sito. I dati saranno trattati solo dagli incaricati e dai Responsabili del
                trattamento nominati da Ariadne S.r.l. in qualità di Titolare del trattamento.
                Nessun altro dato personale degli utenti viene acquisito dai siti Ariadne. Nessun
                dato personale degli utenti verrà in alcun modo ceduto a terzi.
              </p>
              <p>
                <strong>Dati di navigazione</strong>
              </p>
              <p>
                Alcuni dati personali sono raccolti in modo implicito con l'uso dei protocolli di
                comunicazione di Internet durante la normale navigazione. Appartengono a questa
                categoria gli indirizzi IP o i nomi a dominio dei computer usati dagli utenti per
                collegarsi, il metodo usato per sottoporre le richieste al server, e altri parametri
                relativi al sistema operativo e al sistema informatico dell'utente. Questi dati sono
                utilizzati in modo anonimo e solo per fini statistici in relazione all'utilizzo del
                sito.
              </p>
              <p>
                <strong>Cookies</strong>
              </p>
              <p>
                I Siti Ariadne non fanno uso di cookies per la trasmissione di informazioni di
                carattere personale. L'uso di cosiddetti cookies di sessione (che non vengono
                memorizzati in modo persistente sul computer dell'utente e svaniscono con la
                chiusura del browser) è strettamente limitato alla trasmissione di identificativi di
                sessione (costituiti da numeri casuali generati dal server) necessari per consentire
                l'esplorazione sicura ed efficiente del sito. I cookies di sessione utilizzati in
                questo sito evitano il ricorso ad altre tecniche informatiche potenzialmente
                pregiudizievoli per la riservatezza della navigazione degli utenti e non consentono
                l'acquisizione di dati personali identificativi dell'utente.
              </p>
              <p>
                I Siti Ariadne non fanno uso di cookies per la trasmissione di informazioni di
                carattere personale. L'uso di cosiddetti cookies di sessione (che non vengono
                memorizzati in modo persistente sul computer dell'utente e svaniscono con la
                chiusura del browser) è strettamente limitato alla trasmissione di identificativi di
                sessione (costituiti da numeri casuali generati dal server) necessari per consentire
                l'esplorazione sicura ed efficiente del sito. I cookies di sessione utilizzati in
                questo sito evitano il ricorso ad altre tecniche informatiche potenzialmente
                pregiudizievoli per la riservatezza della navigazione degli utenti e non consentono
                l'acquisizione di dati personali identificativi dell'utente.
              </p>
              <p>
                I cookie non di sessione (che quindi vengono conservati anche dopo la fine della
                visita) non contengono informazioni personali e vengono utilizzati nell'ambito di
                quanto descritto sotto, al paragrafo "Pubblicità".
              </p>
              <p>
                Potete rifiutarvi di usare i cookies selezionando l'impostazione appropriata sul
                vostro browser.
              </p>
              <p>
                <strong>Google Analytics</strong>
              </p>
              <p>
                Questo sito web utilizza Google Analytics, un servizio di analisi web fornito da
                Google, Inc. ("Google") per determinare il livello di interesse rispetto ad
                argomenti particolari e per misurare l'efficacia delle comunicazioni. I dati così
                raccolti verranno utilizzati da Ariadne per effettuare una informazione più completa
                e aggiornata sulle varie tipologie di interventi, sui traguardi specifici, i temi
                oggetto di attività e una più efficace promozione delle proprie iniziative.
              </p>
              <p>
                Google Analytics utilizza dei cookies per consentire al sito web di analizzare come
                gli utenti utilizzano il sito. Le informazioni generate dal cookie sull'utilizzo del
                sito web da parte Vostra (compreso il Vostro indirizzo IP) verranno trasmesse a, e
                depositate presso, i server di Google negli Stati Uniti.
              </p>
              <p>
                Google utilizzerà queste informazioni allo scopo di tracciare e esaminare il Vostro
                utilizzo del sito web, compilare report sulle attività del sito web per gli
                operatori del sito web e fornire altri servizi relativi alle attività del sito web e
                all'utilizzo di Internet.
              </p>
              <p>
                Google può anche trasferire queste informazioni a terzi ove ciò sia imposto dalla
                legge o laddove tali terzi trattino le suddette informazioni per conto di Google.
                Google non assocerà il vostro indirizzo IP a nessun altro dato posseduto da Google.
                Potete rifiutarvi di usare i cookies selezionando l'impostazione appropriata sul
                vostro browser, ma ciò potrebbe impedirvi di utilizzare tutte le funzionalità di
                questo sito web. Utilizzando il presente sito web, voi acconsentite al trattamento
                dei Vostri dati da parte di Google per le modalità e i fini sopraindicati.
              </p>
              <p>
                <strong>Pubblicità</strong>
              </p>
              <p>
                Il Sito Ariadne a volte utilizza - sempre in forma anonima - informazioni relative
                alle pagine visitate per proporre, in seguito, annunci pubblicitari corrispondenti
                ai prodotti e servizi verso cui si è dimostrato interesse. Questo tipo di pubblicità
                si basa sul "behavioral retargeting" e funziona grazie a dei cookie che vengono
                depositati nel computer dei navigatori. Questi cookie monitorano le pagine visitate
                per proporre, in altri siti, annunci pubblicitari correlati ad esse.
              </p>
              <p>
                Le informazioni così raccolte non sono in alcun modo riconducibili all'identità
                dell'utente e non vengono condivise con i siti nei quali appare l'annuncio.
              </p>
              <p>
                Tali informazioni vengono gestite anche da terze parti unicamente per le finalità
                sopra indicate. Tali terze parti possono essere: Google Inc., DoubleClick Inc., che
                mettono a disposizione le loro piattaforme di advertising e il loro network di siti
                publisher al fine di mostrare gli annunci agli utenti interessati.
                <br />
                Il Sito Ariadne opera unicamente con terze parti che si impegnano a gestire i dati
                degli utenti nel rispetto delle attuali normative sulla privacy e che aderiscono al
                Self Regulatory Program for Online Behavioral Advertising, pertanto i cookie di
                behavioral retargeting distribuiti possono essere disattivati.
                <br />
                Per approfondire, è possibile consultare la{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  title="privacy policy di Google Inc."
                  href="http://www.google.com/intl/it/policies/privacy/ads/"
                >
                  privacy policy di Google Inc.
                </a>{" "}
                in merito alla pubblicità.
              </p>
              <p>
                <strong>Conferimento dei dati e Consenso dell'interessato</strong>
              </p>
              <p>
                A parte quanto visto per i dati di navigazione, il conferimento dei dati è
                facoltativo e l'eventuale rifiuto non comporta alcuna conseguenza per la navigazione
                sul sito internet. Comunque in modo agevole e semplice in forza del disposto
                dell'art. 130, comma 4 del Codice, ci si potrà opporre all'invio della newsletter o
                al trattamento dei dati in un secondo momento.
              </p>
              <p>
                <strong>Titolare del trattamento</strong>
              </p>
              <p>
                Il titolare del trattamento è Ariadne S.r.l., con sede legale a Milano, Via Visconti
                di Modrone, 11; e sede operativa a Torre d'Isola (PV), via del Torchio 1.
              </p>
              <p>
                <strong>Diritti dell'interessato</strong>
              </p>
              <p>
                L'interessato cui si riferiscono i dati personali ha il diritto in qualunque momento
                di ottenere la conferma dell'esistenza o meno dei medesimi dati e di conoscerne il
                contenuto e l'origine, verificarne l'esattezza o chiederne l'integrazione o
                l'aggiornamento, la rettificazione oppure la cancellazione secondo quanto riportato
                nell'art. 7 D.Lgs. n. 196/03, scrivendo direttamente al Titolare del trattamento,
                per posta o in forma elettronica all'indirizzo info@ariadne.it. In modo agevole e
                semplice in forza del disposto dell'art. 130, comma 4 del Codice, ci si potrà
                opporre in ogni momento all'invio della newsletter.
              </p>
              <p>
                <strong>Art. 7 (Diritto di accesso ai dati personali e altri diritti)</strong>
              </p>
              <p>L'interessato ha diritto di ottenere l'indicazione:</p>
              <p>
                a) dell'origine dei dati personali;
                <br /> b) delle finalità e modalità del trattamento;
                <br /> c) della logica applicata in caso di trattamento effettuato con l'ausilio di
                strumenti elettronici;
                <br /> d) degli estremi identificativi del titolare, dei responsabili e del
                rappresentante designato ai sensi dell'articolo 5, comma 2;
                <br /> e) dei soggetti o delle categorie di soggetti ai quali i dati personali
                possono essere comunicati o che possono venirne a conoscenza in qualità di
                rappresentante designato nel territorio dello Stato, di responsabili o incaricati.
              </p>
              <p>L'interessato ha diritto di ottenere:</p>
              <p>
                a) l'aggiornamento, la rettificazione ovvero, quando vi ha interesse, l'integrazione
                dei dati;
                <br /> b) la cancellazione, la trasformazione in forma anonima o il blocco dei dati
                trattati in violazione di legge, compresi quelli di cui non è necessaria la
                conservazione in relazione agli scopi per i quali i dati sono stati raccolti o
                successivamente trattati;
                <br /> c) l'attestazione che le operazioni di cui alle lettere a) e b) sono state
                portate a conoscenza, anche per quanto riguarda il loro contenuto, di coloro ai
                quali i dati sono stati comunicati o diffusi, eccettuato il caso in cui tale
                adempimento si rivela impossibile o comporta un impiego di mezzi manifestamente
                sproporzionato rispetto al diritto tutelato.
              </p>
              <p>L'interessato ha diritto di opporsi, in tutto o in parte:</p>
              <p>
                a) per motivi legittimi al trattamento dei dati personali che lo riguardano,
                ancorché pertinenti allo scopo della raccolta;
                <br /> b) al trattamento di dati personali che lo riguardano a fini di invio di
                materiale pubblicitario o di vendita diretta o per il compimento di ricerche di
                mercato o di comunicazione commerciale.
              </p>
              <h3>
                <strong>Responsabile Protezione Dati</strong>
              </h3>
              <p>
                <strong>Nome e cognome: </strong>Stefano Mastella
              </p>
              <p>
                <strong>Tipologia DPO:</strong> Persona fisica esterna
              </p>
              <p>
                <strong>Contatti:</strong>&nbsp;Telefono 02712300983 / Mobile&nbsp;+393489102039
              </p>
              <p>
                <strong>Email:</strong>&nbsp;<a href="mailto:dpo@ariadne.it">dpo@ariadne.it</a>
              </p>
              <p>
                <strong>Pec:</strong>&nbsp;
                <a href="mailto:stefano.mastella@pec.itreec.it">stefano.mastella@pec.itreec.it</a>
              </p>
              <p>
                <em>Ultimo aggiornamento 18/01/2022</em>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Privacy;
